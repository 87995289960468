import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '@/shared/globalStyle'
import WorksPageTemplate from '@/components/templates/WorksPageTemplate'
import SEO from '@/shared/seo'
import profile from '../../static/images/profile.jpeg'

const Works: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="WORKS" image={profile} lang="ja" />
    <WorksPageTemplate />
  </Layout>
)

export default Works
